<template>
  <b-card no-body>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <b-row>
          <b-col lg="6">
            <h3>เพิ่มบัญชีย่อย</h3>
          </b-col>
        </b-row>
      </div>
    </b-card-body>
    <b-card-body>
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <b-input-group>
                <div class="col-md-4">
                  <b-form-input
                    :placeholder="`${UserData.agent_username}@`"
                    disabled
                  />
                </div>
                <b-form-input
                  id="usernameInput"
                  v-model="username"
                  placeholder="username"
                />
              </b-input-group>
              <small class="text-danger ml-2" v-if="!username"
                >จำเป็นต้องกรอก</small
              >
            </div>
            <div class="col-md-6 mb-1">
              <b-form-input id="Name" v-model="fullname" placeholder="Name" />
              <small class="text-danger" v-if="!fullname">จำเป็นต้องกรอก</small>
            </div>
            <div class="col-md-6 mb-1">
              <b-form-input
                id="Password"
                v-model="password"
                placeholder="Password"
                type="password"
              />
              <small class="text-danger" v-if="!password">จำเป็นต้องกรอก</small>
            </div>
            <div class="col-md-6 mb-1">
              <b-form-input id="Phone" v-model="phone" placeholder="Phone" type="number"/>
              <small class="text-danger" v-if="!phone">จำเป็นต้องกรอก</small>
            </div>
          </div>
        </div>
      </div>
    </b-card-body>
    <b-card-body>
      <h3>สิทธิ์การใช้งาน</h3>
      <div class="row">
        <div class="col-md-3">
          <b-form-group>
            จัดการสมาชิก
            <b-form-radio-group v-model="management" class="d-flex flex-column">
              <b-form-radio value="off"> ปิด </b-form-radio>
              <b-form-radio value="view"> ดูเท่านั้น </b-form-radio>
              <b-form-radio value="edit"> แก้ไข </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group>
            จัดการหวย
            <b-form-radio-group
              v-model="managelotto"
              class="d-flex flex-column"
            >
              <b-form-radio value="off"> ปิด </b-form-radio>
              <b-form-radio value="view"> ดูเท่านั้น </b-form-radio>
              <b-form-radio value="edit"> แก้ไข </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group>
            รายงานการแทง
            <b-form-radio-group v-model="managebet" class="d-flex flex-column">
              <b-form-radio value="off"> ปิด </b-form-radio>
              <b-form-radio value="view"> ดูเท่านั้น </b-form-radio>
              <b-form-radio value="edit"> แก้ไข </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-3">
          <b-form-group>
            รายงานแพ้ชนะ
            <b-form-radio-group
              v-model="managewinlose"
              class="d-flex flex-column"
            >
              <b-form-radio value="off"> ปิด </b-form-radio>
              <b-form-radio value="view"> ดูเท่านั้น </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>
    </b-card-body>
    <b-card-body class="d-flex justify-content-between flex-wrap pt-0" />
    <div class="row">
      <div class="col-md-12 d-flex justify-content-end">
        <div class="col-md-3 text-right mb-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
            block
            @click="SubmitData()"
          >
            บันทึก
          </b-button>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
// import vSelect from 'vue-select'
import moment from "moment-timezone";
import {
  BInputGroupPrepend,
  BModal,
  BCard,
  BTable,
  BAvatar,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  VBToggle,
  BBadge,
  BFormCheckbox,
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    // vSelect,
    BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BBadge,
    BFormCheckbox,
    BFormRadio,
    BFormRadioGroup,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      username: null,
      fullname: null,
      password: null,
      phone: null,
      management: "off",
      managelotto: "off",
      managebet: "off",
      managewinlose: "off",
      UserData: JSON.parse(localStorage.getItem("userData")),
    };
  },

  mounted() {},
  methods: {
    async SubmitData() {
      const resultCheck = await this.$swal({
        title: `เพิ่มบัญชีย่อย`,
        text: `กรุณายืนยันเพิ่มบัญชีย่อย`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        showClass: {
          popup: "animate__animated animate__shakeX",
        },
        buttonsStyling: false,
      });
      if (resultCheck.isConfirmed) {
        if (!this.username || !this.fullname || !this.password || !this.phone) {
          return this.SwalError("กรุณากรอกข้อมูลให้ครบถ้วน");
        }
        const obj = {
          username: `${this.UserData.agent_username}@${this.username}`,
          fullname: this.fullname,
          password: this.password,
          tel: this.phone,
          allow_member: this.management,
          allow_lotto: this.managelotto,
          allow_bet: this.managebet,
          allow_winlose: this.managewinlose,
          };
          await this.$http.post("http://127.0.0.1:9999/api/SubAccount/create/store", obj).then(response => {
              if (response.data.status) {
                 this.Success(response.data.mes)
                this.$router.push('/SubAccount')
              } else {
                this.SwalError(response.data.mes)
              }
          }).catch(err => {
            this.SwalError(err);
        })
      }
    },
    Success(mes) {
      this.$swal({
        icon: "success",
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
    SwalError(mes) {
      this.$swal({
        icon: "error",
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}

.upload-btn {
  border: 2px dashed #ccc;
  display: inline-block;
  border-radius: 10px;
  padding: 6px 12px;
  cursor: pointer;
  position: relative;
  width: 150px;
  height: 150px;
  font-size: 18px;
  z-index: 0;
}

.upload-btn input[type="file"] {
  display: none;
}

.clear-btn {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  cursor: pointer;
  z-index: 1;
}
</style>
